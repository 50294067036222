import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {MarginBoxComponent, SingleLineComponent3} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import HeaderView from 'view/common/Header';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import FooterView from 'view/common/Footer';
import RewriteOverlay from 'view/common/RewritePracticeOverlay';
import {StringUtils} from 'common/utils/StringUtils';
import writingSelectOption from 'asset/json/writing_select_option1.json';

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [loading, setLoading] = useState(true);
  const [practiceID, setPracticeID] = useState(0);
  const [currentEvalTry, setCurrentEvalTry] = useState(0);
  const [maxEvalTry, setMaxEvalTry] = useState(0);

  const [practiceDateText, setPracticeDateText] = useState('');
  const [evalScore, setEvalScore] = useState(0);

  const [titleText, setTitleText] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [writingJanre, setWritingJanre] = useState('');

  function getPracticeWriting(practice_id, eval_try) {
    const taskResult = async () => {
      try {
        const response = await APIUtils.PracticeHistoryEvalResult(
          authReducer.student_id,
          practice_id,
          eval_try,
        );
        console.log(response);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setPracticeDateText(
            StringUtils.getDateStringKr(response.data.eval_time),
          );
          setEvalScore(response.data.score);
          setTitleText(response.data.title);
          setBodyText(response.data.text);
          setWritingJanre(response.data.writing_janre);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    taskResult();
  }
  function navigateWriting1() {
    localStorage.setItem('practiceBody', bodyText);
    localStorage.setItem('practiceTitle', titleText);
    localStorage.setItem(
      'practiceJanre',
      writingSelectOption.indexOf(writingJanre),
    );
    navigate('/writing1');
  }

  useEffect(() => {
    let inputPracticeID = 0;
    let inputEvalTry = 0;
    if (
      location.state == null &&
      window.localStorage.getItem('practice_id') == null
    ) {
      navigate('/', {replace: true});
      return;
    } else if (location.state == null) {
      inputPracticeID = Number(window.localStorage.getItem('practice_id'));
      inputEvalTry = Number(window.localStorage.getItem('eval_try'));
    } else {
      inputPracticeID = location.state.practice_id;
      inputEvalTry = location.state.eval_try;
      window.localStorage.setItem('practice_id', location.state.practice_id);
      window.localStorage.setItem('eval_try', location.state.eval_try);
    }
    setCurrentEvalTry(inputEvalTry);
    setMaxEvalTry(inputEvalTry);
    setPracticeID(inputPracticeID);
    getPracticeWriting(inputPracticeID, inputEvalTry);

    const backspace = e => {
      if (
        e.keyIdentifier == 'U+0008' ||
        e.keyIdentifier == 'Backspace' ||
        e.keyCode == 8
      ) {
        if (e.target == document.body) {
          e.preventDefault();
          navigate('/mypage', {
            replace: true,
            state: {
              fragmentNum: 5,
            },
          });
          return false;
        }
      }
    };
    window.addEventListener('keydown', backspace, true);
    const preventGoBack = () => {
      history.pushState(null, '', location.href);

      navigate('/mypage', {
        replace: true,
        state: {
          fragmentNum: 5,
        },
      });
    };
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', preventGoBack);
    return () => {
      window.removeEventListener('keydown', backspace, true);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeaderView />
      <MarginBoxComponent>
        <>
          <Box style={{marginTop: '1rem', display: 'flex'}}>
            <Box style={{marginRight: '1rem'}}>{practiceDateText}</Box>
            <Box style={{fontWeight: 'bold'}}>{evalScore + ' 점'}</Box>
          </Box>
          <SingleLineComponent3
            marginTop={'1rem'}
            text={titleText}
            currentIndex={currentEvalTry}
            maxIndex={maxEvalTry}
            leftClickEvent={() => {
              setCurrentEvalTry(prev => {
                if (prev > 1) {
                  getPracticeWriting(practiceID, prev - 1);
                  return prev - 1;
                }
              });
            }}
            rightClickEvent={() => {
              setCurrentEvalTry(prev => {
                if (prev < maxEvalTry) {
                  getPracticeWriting(practiceID, prev + 1);
                  return prev + 1;
                }
              });
            }}
          />
          <Box
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: '2rem',
              fontSize: '1.2rem',
              marginBottom: '3rem',
              gap: '0.8rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {bodyText.split('\n').map((paragraph, idx) => {
              return <span key={'paragraph' + idx}>{paragraph}</span>;
            })}
          </Box>
        </>
      </MarginBoxComponent>
      <RewriteOverlay
        resultEvent={navigateWriting1}
        date={practiceDateText}
        title={titleText}
        text={bodyText}
      />
      <FooterView />;
    </Box>
  );
};
export default MainPage;
