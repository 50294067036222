import React from 'react';
import {Button, Box} from '@mui/material';
import PropTypes from 'prop-types';
import CopyIcon from 'asset/imageV2/icon_copy.svg';

function textCopy(date, title, text) {
  const copyText =
    '날짜 : ' +
    date +
    '\n' +
    '제목 : ' +
    title +
    '\n' +
    '본문 : \n' +
    text +
    '\n';
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        alert('글이 복사되었습니다.'); // props 대신 alert로 결과 알림
      })
      .catch(() => {
        alert('복사를 다시 시도해주세요.');
      });
  } else {
    // 대체 흐름: execCommand 사용
    if (!document.queryCommandSupported('copy')) {
      alert('복사하기가 지원되지 않는 브라우저입니다.');
      return;
    }
    const textarea = document.createElement('textarea');
    textarea.value = copyText;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      alert('글이 복사되었습니다.');
    } catch (err) {
      alert('복사를 다시 시도해주세요.');
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

const RewriteOverlay = props => {
  return (
    <Box
      style={{
        position: 'fixed',
        bottom: '0',
        top: '0',
        right: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 3,
      }}
    >
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#00C500',
          color: 'black',
          fontSize: '0.8rem',
          fontWeight: 'bold',
        }}
        onClick={() => {
          props.resultEvent();
        }}
      >
        <img
          src={CopyIcon}
          alt="CopyIcon"
          style={{
            width: '1.2rem',
            height: '1.2rem',
          }}
        />
        <Box
          style={{
            color: 'white',
          }}
        >
          {'이어쓰기'}
        </Box>
      </Button>

      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FF9900',
          color: 'black',
          fontSize: '0.8rem',
          fontWeight: 'bold',
          marginTop: '1rem',
        }}
        onClick={() => {
          textCopy(props.date, props.title, props.text);
        }}
      >
        <img
          src={CopyIcon}
          alt="CopyIcon"
          style={{
            width: '1.2rem',
            height: '1.2rem',
          }}
        />
        <Box
          style={{
            color: 'white',
          }}
        >
          {'복사하기'}
        </Box>
      </Button>
    </Box>
  );
};
RewriteOverlay.propTypes = {
  resultEvent: PropTypes.func,
  text: PropTypes.text, // 복사할 텍스트
  title: PropTypes.title, // 복사할 텍스트
  date: PropTypes.date,
};
export default RewriteOverlay;
