import React from 'react';
import {Box, Button} from '@mui/material';
import logoKakao from 'asset/imageV2/katalk.png';
import logoKeewiL from 'asset/imageV2/keewiL.png';
import logoBinkan from 'asset/imageV2/binkan.png';
import logoContest from 'asset/imageV2/contest.png';
import logoKeewit from 'asset/image/logo_keewit.png';

const OverlayView = () => {
  return (
    <Box
      style={{
        paddingBottom: '2rem',
        position: 'fixed',
        bottom: '30%',
        right: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'end',
        zIndex: 3,
      }}
    >
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F9E000',
          color: 'black',
          fontSize: '0.6rem',
          fontWeight: 'bold',
        }}
        onClick={() => window.open('http://pf.kakao.com/_EYTln', '_blank')}
      >
        <img src={logoKakao} alt="logoKakao" style={{width: '2rem'}} />
        <Box>{'카톡 문의'}</Box>
      </Button>
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F8CC62',
          color: 'black',
          fontSize: '0.6rem',
          fontWeight: 'bold',
          marginTop: '1rem',
          wordWrap: 'break-word',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
        }}
        onClick={() => {
          window.open('https://korean.ai/train/main/', '_blank');
        }}
      >
        <img src={logoBinkan} alt="logoBinkan" style={{width: '2rem'}} />
        <Box style={{lineHeight: '1.0rem', textAlign: 'center'}}>
          문해력 더하기
        </Box>
      </Button>
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#81D7FF',
          color: 'black',
          fontSize: '0.6rem',
          fontWeight: 'bold',
          marginTop: '1rem',
          wordWrap: 'break-word',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
        }}
        onClick={() => {
          window.open('https://korean.ai/main/', '_blank');
        }}
      >
        <img src={logoContest} alt="logoContest" style={{width: '2rem'}} />
        <Box style={{lineHeight: '1.0rem', textAlign: 'center'}}>
          글쓰기 대회
        </Box>
      </Button>
    </Box>
  );
};
export default OverlayView;
